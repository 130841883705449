@import "../../../../../../assets/styles/mixins.scss";

.logo {
  height: 46px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  border: 1px solid $gray94;

  img {
    height: 16px;
    margin-right: 10px;
  }
}

.company {
  text-transform: uppercase;
  color: $black;
  font-weight: bold;

  :global(.ant-layout-sider-collapsed) & {
    display: none;
  }
}
