@import "../../../../assets/styles/mixins.scss";

.popoverContent {
  width: 384px;
}

.hoverable {
  cursor: pointer;
}

.menuIcon {
  background-color: $menuBg;
}

.notificationOverlayWrapper {
  :global {
    .ant-popover-content {
      .ant-popover-arrow {
        border-top-color: $blue-lighten !important;
        border-left-color: $blue-lighten !important;
      }

      .ant-popover-inner {
        .ant-popover-inner-content {
          max-height: 500px;
          overflow-y: auto;
        }

        .ant-popover-title {
          background-color: $blue-lighten;

          h3 {
            color: white;
            margin: 0.5em 0;
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
