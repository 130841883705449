@import "../../../../assets/styles/mixins.scss";

.hoverable {
  cursor: pointer;
}

.menuIcon {
  background-color: $menuBg;
}

.badgeCount {
  :global(.ant-badge-count) {
    background-color: $black-lighten-more;
  }
}
