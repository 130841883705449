@import url('https://use.typekit.net/wsz0eij.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,900&display=swap');
@import 'mixins.scss';

body {
  font-family: myriad-pro, sans-serif !important;
}

label {
  font-weight: 600 !important;
}

.ant-drawer-content-wrapper,
.preview-pane {
  @media (max-width: $md-max-width) {
    max-width: 80vw !important;
  }
}

.utils__content {
  padding: 1.5rem;
  max-width: 120rem;
  margin: 0 auto;
}

.ant-layout-content {
  background: #f4f7fb !important;
  background: #f6f5fa !important;
}

.ant-layout-header {
  height: 46px !important;
  line-height: 46px !important;
}

.ant-page-header {
  .ant-descriptions {
    margin-left: 32px;
  }
}

.ant-breadcrumb {
  font-size: 11px !important;
}

.ant-skeleton-content .ant-skeleton-title {
  height: 20px !important;
  margin-top: 8px !important;
}

.ant-descriptions-item-content,
.ant-descriptions-item-label {
  font-size: 13px !important;
  color: #c0bdd0 !important;
}

.card,
.ant-card {
  box-shadow: 0 4px 16px 0 #00000008 !important;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03) !important;
  border-radius: 6px !important;
  margin: 0 0 3rem 0 !important;
}

.ant-card img {
  max-width: 100%;
}

.dark .ant-card-head {
  color: rgb(255, 255, 255) !important;
  background: #00152a !important;
}

.ant-card-head {
  font-weight: 600 !important;
  font-size: 18px !important;
}

.custom.ant-row {
  margin: 0 0 1rem 0 !important;
}

.ant-input:focus,
.ant-select-focused .ant-select-selector,
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  border-color: #93ceff !important;
  border-right-width: 1px !important;
  outline: 0 !important;
  box-shadow: 0 0 3px 3px rgba(37, 150, 255, 0.1) !important;
}

.ant-select-selection__placeholder,
.ant-select-search__field__placeholder,
.ant-input::placeholder {
  color: #e2e2e2 !important;
  font-weight: 200 !important;
}

.public-DraftEditorPlaceholder-root {
  color: #e2e2e2 !important;
  padding: 20px 12px !important;
  padding: 0 !important;
}

.rdw-editor-toolbar {
  border: none !important;
  padding: 0 !important;
}

.ant-form-item-control {
  line-height: 22px !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
  height: 46px !important;
  line-height: 46px !important;
  padding: 0 24px !important;
}

body .ant-dropdown-menu,
.ant-dropdown-menu-submenu-popup {
  border: none !important;
}

body .language-select .ant-dropdown-menu-item,
body .language-select .ant-dropdown-menu-submenu-title {
  padding: 3px 14px !important;
  font-size: 12px !important;
  line-height: 18px !important;
}

.ant-cascader-menu {
  min-width: 250px !important;
  height: 280px !important;
  margin: 20px 0 !important;
  padding: 0 !important;
  border-right: 1px solid #e8e8e8;
}

.ant-cascader-menu-item {
  padding: 5px 12px !important;
  line-height: 24px !important;
}

.ant-anchor-wrapper {
  background: none !important;
}

.ant-anchor-link {
  padding: 12px 0 12px 26px !important;
  line-height: 2 !important;
}

.ant-form-item-label {
  line-height: 30px !important;
}

.ant-select-select-single,
.ant-select-selection--single {
  height: 38px !important;
  border: 1px solid #f1f1f1 !important;
  border-top-width: 2px !important;
}

.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  width: auto;
}

.ant-alert {
  border: none !important;
}

.ant-tag {
  font-weight: 500;
}

.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0 !important;
}

.ant-modal-mask {
  z-index: $base-z-index + 1;
}

.ant-modal-wrap {
  z-index: $base-z-index + 2;
}

.markdown {
  p {
    line-height: 1.6;
  }

  h2 {
    font-size: rem(18) !important;
  }
}

.ant-table {
  border: none !important;

  .ant-table-content {
    background: white;
  }

  p {
    margin: 0 0 2px 0 !important;
  }
}

.ant-table.virtual-table tbody > tr.ant-table-row:hover > td {
  background: inherit !important;
}

.text-shorten-1 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding: 0 !important;
  overflow: hidden;
}

.text-shorten-2 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding: 0 !important;
  overflow: hidden;
}

.text-shorten-3 {
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  padding: 0 !important;
  overflow: hidden;
}

/* Form styling */

.ant-checkbox-group {
  line-height: 2 !important;
}

.ant-checkbox-group-item {
  display: block !important;
  margin-right: 0 !important;
}

.upload-field {
  display: block;
  margin: 2rem 0;
}

/* Image in upload box  */

.wdn-image-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $base-z-index + 2;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
}

.wdn-modal-content {
  margin: auto;
  width: 85%;
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}

.wdn-upload {
  width: 156px;
  height: 156px;
  margin-bottom: 20px;
  text-align: center;
  vertical-align: top;
  background-color: $gray98;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}

.wdn-upload:hover {
  border-color: #1890ff;
}

.wdn-upload-label {
  font-weight: 400 !important;
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 15px;
}

.wdn-image {
  cursor: pointer;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  padding: 8px;
  display: inline-block;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  transition: background-color 300ms ease-in-out, border-color 300ms ease-in-out,
    padding 150ms ease-in-out;
  &.disabled {
    pointer-events: none;
    background-color: #f5f5f5;
    span {
      color: #ccc;
    }
  }
}

.wdn-image-delete {
  display: table !important;
  background-color: #e5e5e5;
  display: none !important;
  position: relative;
  left: 80px;
  bottom: 14px;
  padding: 2px;
}

.wdn-image:hover {
  background-color: #e5e5e5 !important;
  border-color: #40a9ff !important;
  padding: 4px !important;
}

.wdn-image:hover .wdn-image-delete {
  display: table !important;
}

#wdn-image-selected {
  padding: 4px;
  background-color: #e5e5e5;
  border-color: #40a9ff;
}

.wdn-upload-container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  object-fit: contain;
}

.crossFix {
  .ant-select-clear {
    margin-top: -15px !important;
  }
  flex-grow: 1;
}

.wdn-image-list {
  overflow-y: scroll;
  height: 448px;
  width: 100%;
  .ant-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    .ant-spin-nested-loading {
      flex-grow: 1;
    }
  }
}

.wdn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.graphiql-container * {
  box-sizing: unset !important;
}

/* Full Calendar Css */
.fc-view-harness,
.fc-view-harness-active,
.fc-view-harness .fc-view-harness-active {
  background-color: white !important;
}

.fc-list-event,
.fc-event,
.fc-event-start,
.fc-event-end,
.fc-event-today,
.fc-event-future {
  cursor: pointer !important;
}

// Provider Overview
.provider-overview-card {
  margin: 0 !important;
}

.provider-overview-search-highlight {
  color: #f50;
}

.unPublishMarker {
  color: $danger;
}
